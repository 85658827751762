import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles';
import { RichText } from 'prismic-reactjs';
import linkResolver from 'utils/linkResolver';
import htmlSerializer from 'utils/htmlSerializer';
import OverlayBg from './images/ovoherooverlay.png';
import styled from 'styled-components';

import { Link } from 'gatsby';

export const Background = styled.div`
  background-image: ${props => `url(${props.image})`};
`;

const styles = theme => ({
  hero: {
    height: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
      minHeight: '450px',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '600px',
      paddingTop: '0px',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '600px',
      paddingTop: '0px',
    },
    [theme.breakpoints.down('md')]: {
      backgroundPositionX: 'calc(100% + 430px) !important',
    },
  },
  subTextBoxWrapper: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: '-40px',
    },
  },
  subTextBox: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    clipPath: 'polygon(0 10%, 100% 0, 100% 100%, 0 100%)',
    '-webkit-clip-path': 'polygon(0 10%, 100% 0, 100% 100%, 0 100%)',
    [theme.breakpoints.up('sm')]: {
      clipPath: 'polygon(0 15%, 100% 0, 100% 100%, 0 100%)',
      '-webkit-clip-path': 'polygon(0 15%, 100% 0, 100% 100%, 0 100%)',
    },
    [theme.breakpoints.up('md')]: {
      clipPath: 'polygon(0 25%, 100% 0, 100% 100%, 0 100%)',
      '-webkit-clip-path': 'polygon(0 25%, 100% 0, 100% 100%, 0 100%)',
    },
  },
  heroSubLine: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
    },
  },
  overlay: {
    backgroundImage: `url(${OverlayBg})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 10,
  },
});

function HeroSecondaryPage(props) {
  const {
    heroTitleRichText,
    heroTitle,
    heroImage,
    heroContent,
    spotlightCotent,
    spotlightLink,
    classes,
  } = props;

  const showPlainTitle = heroTitleRichText ? false : true;

  return (
    <Container>
      <Box
        component={Grid}
        container
        justifyContent="space-between"
        alignItems="center"
        mt={[4, 4, 8]}
        mb={4}
      >
        <Grid item xs={12} md={6} lg={5}>
          <Box>
            <Box mb={2}>
              {showPlainTitle && (
                <Typography
                  component="h1"
                  variant="h1"
                  align="left"
                  className={classes.heroTitle}
                >
                  {heroTitle}
                </Typography>
              )}
              {!showPlainTitle && heroTitleRichText && (
                <Typography component="div">
                  <RichText
                    render={heroTitleRichText}
                    linkResolver={linkResolver}
                    htmlSerializer={htmlSerializer}
                  />
                </Typography>
              )}
            </Box>
            {heroContent && (
              <Typography component="div">
                <RichText
                  render={heroContent}
                  linkResolver={linkResolver}
                  htmlSerializer={htmlSerializer}
                />
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item md={4} lg={5}>
          <img src={heroImage} alt="OVO" className="w-100" />
        </Grid>
      </Box>
    </Container>
  );
}

HeroSecondaryPage.propTypes = {
  heroTitleRichText: PropTypes.array,
  heroTitle: PropTypes.array.isRequired,
  heroImage: PropTypes.object.isRequired,
  heroContent: PropTypes.array,
  spotlightCotent: PropTypes.string,
  spotlightLink: PropTypes.string,
  classes: PropTypes.object,
};

export default withStyles(styles)(HeroSecondaryPage);
