import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Box from '@material-ui/core/Box';
import Layout from 'components/layout';
import HeroSecondaryPage from 'components/hero/HeroSecondaryPage';
import Spacer from 'components/spacer';
import {
  ImageLeftTextRight,
  FullWidthTextImage,
  ExternalLinksGrid,
  ThreeColumn,
  TwoColumn,
  Locations,
  StackedImageLinks,
  ImageSlider,
  ImageCard,
  VideoBlock,
  DepartmentLiveJobs,
  FAQ,
  Steps,
  TextBlock,
} from 'components/slices';
import { withPreview } from 'gatsby-source-prismic';

function Department(props) {
  const { data } = props;
  const doc = data.prismicDepartment;
  if (!doc) return null;
  return (
    <Layout headerOpacity="0.9">
      <Box component="div">
        <HeroSecondaryPage
          heroTitle={doc.data.banner_title.text}
          heroImage={doc.data.banner_image.url}
          heroContent={
            doc.data.banner_content ? doc.data.banner_content.raw : ''
          }
        />
      </Box>
      {doc.data.body && (
        <React.Fragment>
          {doc.data.body.map((item, i) => {
            switch (item.slice_type) {
              case 'image_left_text_right':
                return (
                  <Spacer key={i}>
                    <ImageLeftTextRight data={item.primary} />
                  </Spacer>
                );
              case 'full_width_text_image':
                return (
                  <Spacer key={i}>
                    <FullWidthTextImage data={item.primary} />
                  </Spacer>
                );
              case 'external_links_grid':
                return (
                  <Spacer key={i}>
                    <ExternalLinksGrid
                      data={item.primary}
                      fields={item.fields}
                    />
                  </Spacer>
                );
              case 'three_column':
                return (
                  <Spacer key={i}>
                    <ThreeColumn data={item.primary} fields={item.fields} />
                  </Spacer>
                );
              case 'two_column':
                return (
                  <Spacer key={i}>
                    <TwoColumn data={item.primary} fields={item.fields} />
                  </Spacer>
                );
              case 'locations':
                return (
                  <Spacer key={i}>
                    <Locations data={item.primary} fields={item.fields} />
                  </Spacer>
                );
              case 'stacked_image_links':
                return (
                  <Spacer key={i}>
                    <StackedImageLinks
                      data={item.primary}
                      fields={item.fields}
                    />
                  </Spacer>
                );
              case 'image_slider':
                return (
                  <Spacer key={i}>
                    <ImageSlider data={item.primary} fields={item.fields} />
                  </Spacer>
                );
              case 'image_card':
                return (
                  <Spacer key={i}>
                    <ImageCard data={item.primary} />
                  </Spacer>
                );
              case 'video_block':
                return (
                  <Spacer key={i}>
                    <VideoBlock data={item.primary} />
                  </Spacer>
                );
              case 'faq':
                return (
                  <Spacer key={i}>
                    <FAQ data={item.primary} fields={item.fields} />
                  </Spacer>
                );
              case 'steps':
                return (
                  <Spacer key={i}>
                    <Steps data={item.primary} fields={item.items} />
                  </Spacer>
                );
              case 'text_block':
                return <TextBlock data={item.primary} />;
              default:
                return null;
            }
          })}
        </React.Fragment>
      )}
      {doc.data.greenhouse_id && (
        <Spacer>
          <DepartmentLiveJobs
            ghId={doc.data.greenhouse_id}
            departmentTitle={doc.data.banner_title.text}
            departments={true}
          />
        </Spacer>
      )}
    </Layout>
  );
}

Department.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withPreview(Department);

export const query = graphql`
  query DepartmentTypePageQuery($uid: String!) {
    prismicDepartment(uid: { eq: $uid }) {
      uid
      data {
        greenhouse_id
        banner_title {
          text
        }
        banner_content {
          text
          raw
        }
        banner_image {
          url
        }
        body {
          ... on PrismicDepartmentBodyImageSlider {
            slice_type
            slice_label
            primary {
              title {
                text
              }
              caption {
                text
              }
            }
            items {
              image {
                url
                alt
              }
            }
          }
          ... on PrismicDepartmentBodyImageCard {
            slice_type
            slice_label
            primary {
              title {
                text
              }
              image {
                url
                alt
              }
              body {
                text
              }
            }
          }
          ... on PrismicDepartmentBodyVideoBlock {
            slice_type
            primary {
              video_url {
                text
                raw
                html
              }
              title {
                text
                raw
              }
              content {
                raw
              }
              image {
                url
                alt
              }
            }
          }
          ... on PrismicDepartmentBodyFaq {
            slice_type
            slice_label
            primary {
              title {
                text
              }
            }
            items {
              answer {
                text
              }
              question {
                text
              }
            }
          }
          ... on PrismicDepartmentBodySteps {
            slice_type
            slice_label
            primary {
              title {
                text
              }
              caption {
                text
              }
            }
            items {
              description {
                text
              }
              icon_image {
                url
                alt
              }
              step_title {
                text
              }
            }
          }
          ... on PrismicDepartmentBodyTextBlock {
            slice_type
            slice_label
            primary {
              content {
                html
                raw
                text
              }
            }
          }
        }
      }
    }
  }
`;
